body {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* These are rendered after applying dangerouslySetInnerHTML */
img.emoji {
  height: 1.125em;
  width: auto;
}

/* Fixes for handling different-sized swipeable views */
.react-swipeable-view-container > div {
  transition: max-height 0.6s ease-out;
}

.react-swipeable-view-container > div[aria-hidden="false"] {
  max-height: 100%;
}

.react-swipeable-view-container > div[aria-hidden="true"] {
  max-height: 0;
}

/* Embed images to chatbot */
img.embedded {
  max-width: 300px;
}
